import React, { useEffect } from 'react';

const Content = ({ question }) => {


  useEffect(() => {
    const h3Elements = document.querySelectorAll('.ptag h3');
    h3Elements.forEach((el) => {
      el.style.color = 'blue';
      el.style.marginTop = "7px"
      
    });
  }, [question]);

  if (!question) return <div className="content-custom">Select a question to see the content.</div>;

  return (
    <div className="content-custom" style={{ maxHeight: '93vh', border: '1px solid #ccc', padding: '20px', overflowY: 'auto' }}>
      <h2 style={{ fontSize: "large" }}>{question.question}</h2>


      <p
        dangerouslySetInnerHTML={{ __html: question.answer }}
        style={{ fontSize: "large", color: "rgb(83, 83, 83)" }}
        className='ptag'>
      </p>
      {question.image && question.image.length > 0 &&
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
          {question.image.map((value, index) => (
            <img
              key={index}
              src={value}
              alt={question.question}
              className="img-fluid"
              style={{
                maxWidth: '100%',
                height: 'auto',
                maxHeight: '350px',
                borderRadius: '8px',
                objectFit: 'cover'
              }}
            />
          ))}
        </div>
      }
      <br></br>
      {question.code && (
        <>
          <h4>CODE SNIPPET</h4>
          <pre style={{ backgroundColor: '#f5f5f5', padding: '10px', borderRadius: '5px', overflowX: 'auto' }}>
            <code style={{ fontFamily: 'monospace', fontSize: '1em', color: '#d63384' }}>
              {question.code}
            </code>
          </pre>
        </>
      )}
    </div>
  );
};

export default Content;
