import React, { useEffect, useRef } from "react";
import Typed from "typed.js";
import "./Animation.css";

const AnimationSection = ({ content, removeTag }) => {
  const spanRef = useRef(null);

  useEffect(() => {
    const typed = new Typed(spanRef.current, {
      strings: ["Developer", "Full Stack Developer"],
      loop: true,
      typeSpeed: 100,
      backSpeed: 50,
      backDelay: 2000,
    });

    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <div className="hero-container">
      {removeTag ? (
        <>
          <div>{`${content} `} | I'm  <br /> <span ref={spanRef} className="typed"></span></div>
        </>
      ) : (
        <p>
          {`${content} `} | I'm <span ref={spanRef} className="typed"></span>
        </p>
      )}
    </div>
  );
};

export default AnimationSection;
