import { React, useEffect } from "react";
import "./App.css";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import Home from "./components/home/home";
import Profile from "./components/profile/profile";
import RemoteControl from "./components/web-remote-controller/remote-control/remote-control";
import Topic from "./components/topic/Topic";

function App() {
  // const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // window.onmessage =(event)=>{
  //   if (event.data?.navigate) {
  //     navigate(event.data.navigate);
  //   }
  // }

  return (
    <>
      <Routes>
        <Route path="/" element={<Home currentPage={"home"} />} />

        <Route index element={<Home currentPage={"home"} />} />
        <Route
          path="/developer"
          element={<Profile currentPage={"developer"} />}
        />
        <Route path="/web-remote-control" element={<RemoteControl/>} />
        <Route path="/topic" element={<Topic/>} />
        <Route path="*" element={<Home currentPage={"home"} />} />
      </Routes>
    </>
  );
}

export default App;
