import { React, useEffect, useState, useRef } from "react";
import Navbar from "../navbar/navbar";
import MainPage from "../main-page/main-page";
import ToggleError from "../error/error";
import ScreenSelectModal from "../screen-select-Modal/screenSelectModal";
import { io } from "socket.io-client";
import ClientPCStream from "../client-pc-stream/client-pc-stream";
import {
  SOCKET_EVENT,
  WEBRTC_STATUS,
  keyboardEventType,
  mouseEventType,
  mouseType,
  LOCAL_STORAGE_KEY,
  ELECTRON_CONTROLBAR_EVENT,
  keyboardKey,
} from "../../../constant/constant";
import config from "../../../config/config.json";
import PcControlbar from "../pc-controlbar/pc-controlbar";

var socket;
var clearErrorTimeout;

const useCustomHook = (initialState) => {
  const [state, setState] = useState(initialState);
  const ref = useRef(initialState);

  useEffect(() => {
    ref.current = state;
  }, [state]);

  return [state, setState, ref];
};

const RemoteControl = () => {
  const [toggleError, setToggleError] = useState(false);
  const [screenSelectModal, setScreenSelectModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [credential, setCredential] = useState(null);
  const remoteId = useRef(null);
  const [serverConnected, setServerConnected] = useState(false);
  const [videoTab, setVideoTab] = useState(false);
  //---------------//
  const [webControlbar, setWebControlbar] = useState({
    window: false,
    share: false,
  });
  //------------------//
  const [stream, setPCStream, pcStream] = useCustomHook(null);
  const [webrtcStatus, setWebrtcStatus] = useState(false);
  const clientPc = useRef(null);
  const clientPcId = useRef(null);
  const pcSources = useRef(null);
  const [clientStream, setClientStream] = useState(null);
  const sender = useRef(null);
  const [clientPcControl, setClientPcControl] = useState(false);
  const [pcControl, setPcControl] = useState(false);
  const configuration = {
    iceServers: [
      { urls: "stun:stun.l.google.com:19302" },
      { urls: "stun:stun1.l.google.com:19302" },
      { urls: "stun:stun2.l.google.com:19302" },
      {
        urls: "turn:numb.viagenie.ca",
        credential: "muazkh",
        username: "webrtc@live.com",
      },
      {
        urls: "turn:192.158.29.39:3478?transport=udp",
        credential: "JZEOEt2V3Qb0y27GRntt2u2PAYA=",
        username: "28224511:1379330808",
      },
      {
        urls: "turn:192.158.29.39:3478?transport=tcp",
        credential: "JZEOEt2V3Qb0y27GRntt2u2PAYA=",
        username: "28224511:1379330808",
      },
      {
        urls: "turn:turn.bistri.com:80",
        credential: "homeo",
        username: "homeo",
      },
      {
        urls: "turn:turn.anyfirewall.com:443?transport=tcp",
        credential: "webrtc",
        username: "webrtc",
      },
    ],
  };
  
  const rtcPeerConnection = useRef(new RTCPeerConnection(configuration));

  const handleRemoteRequest = (clientCredential) => {
    const containsOnlyStrings = (input) => {
      const regex = /^[a-zA-Z\s]+$/;
      return regex.test(input);
    };

    if (
      clientCredential.userName === "" ||
      clientCredential.remoteId === "" ||
      clientCredential.password === ""
    ) {
      setToggleError(true);
      setErrorMessage("Ensure all fields are filled before submitting.");
      clearTimeout(clearErrorTimeout);
      clearErrorTimeout = setTimeout(() => {
        setErrorMessage(null);
        setToggleError(false);
      }, 5000);
      return;
    }

    if (!containsOnlyStrings(clientCredential.userName)) {
      setToggleError(true);
      setErrorMessage("User name must be alphabet.");
      clearTimeout(clearErrorTimeout);
      clearErrorTimeout = setTimeout(() => {
        setErrorMessage(null);
        setToggleError(false);
      }, 5000);
      return;
    }

    clientCredential["userId"] = credential.id;
    localStorage.removeItem(LOCAL_STORAGE_KEY.REMOTE_CONTROL_SESSION);
    // console.log("clientCredential", clientCredential);
    socket.emit(SOCKET_EVENT.REMOTE_ACCESS_REQUEST, clientCredential);
  };

  const handleScreenSelectModal = (screen) => {
    setScreenSelectModal(false);
    if (null === screen && clientPc.current.userId) {
      const data = {
        clientId: clientPc.current.userId,
        pcId: remoteId.current,
        message: `PC Remote ID :${remoteId.current} Unable Select Any Window, Request Failed.`,
      };
      socket.emit(SOCKET_EVENT.REMOTE_CONTROL_REJECTION, data);
      return;
    }
    getUserMedia(screen);
  };

  const getUserMedia = async (screen) => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: {
          mandatory: {
            chromeMediaSource: "desktop",
            chromeMediaSourceId: screen.id,
          },
        },
      });

      await sendRemoteAcceptConfirmation();
      addStream(stream);
    } catch (e) {
      console.log(e);
    }
  };

  const getUserMediaWeb = async () => {
    try {
      const stream = await navigator.mediaDevices.getDisplayMedia({
        video: true,
      });

      stream.getVideoTracks()[0].onended = () => {
        setWebControlbar((prev) => ({ ...prev, share: false }));
        if (null !== pcStream.current) {
          const mediaStreamTracks = pcStream.current.getTracks();
          mediaStreamTracks.forEach((track) => {
            track.stop();
          });
          setPCStream(null);
        }
      };

      await sendRemoteAcceptConfirmation();
      addStream(stream);
    } catch (e) {
      if (e.message === "Permission denied") {
        const data = {
          clientId: clientPc.current.userId,
          pcId: remoteId.current,
          message: `PC Remote ID :${remoteId.current} Denied Remote Access Request`,
        };
        socket.emit(SOCKET_EVENT.REMOTE_CONTROL_REJECTION, data);
      }
      console.log(e);
    }
  };

  const sendRemoteAcceptConfirmation = async () => {
    return new Promise((reslove, reject) => {
      const isElectron = () => {
        // Renderer process
        if (
          typeof window !== "undefined" &&
          typeof window.process === "object" &&
          window.process.type === "renderer"
        ) {
          return true;
        }

        // Main process
        if (
          typeof process !== "undefined" &&
          typeof process.versions === "object" &&
          !!process.versions.electron
        ) {
          return true;
        }

        // Detect the user agent when the `nodeIntegration` option is set to true
        if (
          typeof navigator === "object" &&
          typeof navigator.userAgent === "string" &&
          navigator.userAgent.indexOf("Electron") >= 0
        ) {
          return true;
        }

        return false;
      };
      const remotePc = remoteId.current;
      const controllerPc = clientPc.current.userId;
      const remoteControlPermission = isElectron();
      const data = { remotePc, controllerPc, remoteControlPermission };

      socket.emit(SOCKET_EVENT.ALLOW_REMOTE_CONTROL_ACCESS, data);

      setTimeout(() => {
        reslove(true);
      }, 3000);
    });
  };

  const createRtcOffer = () => {
    try {
      rtcPeerConnection.current
        .createOffer({
          offerToReceiveVideo: true,
        })
        .then((sdp) => {
          rtcPeerConnection.current.setLocalDescription(sdp);
          console.log("sending offer", sdp);
          const data = {
            sdp: sdp,
            clientId: clientPc.current.userId,
            pcId: remoteId.current,
          };
          socket.emit(SOCKET_EVENT.RTC_OFFER, data);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const addStream = (stream) => {
    setPCStream(stream);
    const videoTrack = stream.getTracks()[0];
    sender.current = rtcPeerConnection.current.addTrack(videoTrack, stream);
  };

  const handleEndRemoteControl = () => {
    const senders = rtcPeerConnection.current.getSenders();
    senders.forEach((sender) => rtcPeerConnection.current.removeTrack(sender));
    if (null !== pcStream.current) {
      const mediaStreamTracks = pcStream.current.getTracks();
      mediaStreamTracks.forEach((track) => {
        track.stop();
      });
    }
    rtcPeerConnection.current.close();
    setClientStream(null);
    setWebrtcStatus(WEBRTC_STATUS.DISCONNECTED);
    setPCStream(null);
    sender.current = null;
    clientPcId.current = null;
    clientPc.current = null;
    rtcPeerConnection.current = null;
    rtcPeerConnection.current = new RTCPeerConnection(configuration);
    setClientPcControl(false);
    setVideoTab(false);
    socket.emit(SOCKET_EVENT.REMOVE_ACCESS_SESSION, remoteId.current);
    localStorage.removeItem(LOCAL_STORAGE_KEY.REMOTE_ACCESS_SESSION);
    localStorage.removeItem(LOCAL_STORAGE_KEY.REMOTE_CONTROL_SESSION);
  };

  const handleScreenSelectModalAfterConnected = async (screen) => {
    setScreenSelectModal(false);
    if (screen === null) return;

    const stream = await navigator.mediaDevices.getUserMedia({
      audio: false,
      video: {
        mandatory: {
          chromeMediaSource: "desktop",
          chromeMediaSourceId: screen.id,
        },
      },
    });

    if (null !== pcStream.current) {
      const mediaStreamTracks = pcStream.current.getTracks();
      mediaStreamTracks.forEach((track) => {
        track.stop();
      });
    }

    setVideoTab(false);

    if (null !== sender.current) {
      setPCStream(stream);
      const videoTrack = stream.getTracks()[0];
      sender.current.replaceTrack(videoTrack);
      return;
    }
    addStream(stream);
  };

  const handleShareScreen = async () => {
    if (window.api) {
      window.api.allowSystemSources();
      return;
    }
    try {
      const stream = await navigator.mediaDevices.getDisplayMedia({
        video: true,
      });

      stream.getVideoTracks()[0].onended = () => {
        setWebControlbar((prev) => ({ ...prev, share: false }));
        if (null !== pcStream.current) {
          const mediaStreamTracks = pcStream.current.getTracks();
          mediaStreamTracks.forEach((track) => {
            track.stop();
          });
          setPCStream(null);
        }
      };

      if (null !== pcStream.current) {
        const mediaStreamTracks = pcStream.current.getTracks();
        mediaStreamTracks.forEach((track) => {
          track.stop();
        });
      }

      setVideoTab(false);

      if (null !== sender.current) {
        setPCStream(stream);
        const videoTrack = stream.getTracks()[0];
        sender.current.replaceTrack(videoTrack);
        return;
      }

      addStream(stream);
    } catch (err) {
      console.log("error", err);
    }
  };

  const webControlbarShareScreen = async () => {
    if (null !== pcStream.current) {
      const mediaStreamTracks = pcStream.current.getTracks();
      mediaStreamTracks.forEach((track) => {
        track.stop();
      });
      setPCStream(null);
      return;
    }

    try {
      const stream = await navigator.mediaDevices.getDisplayMedia({
        video: true,
      });

      stream.getVideoTracks()[0].onended = () => {
        setWebControlbar((prev) => ({ ...prev, share: false }));
        if (null !== pcStream.current) {
          const mediaStreamTracks = pcStream.current.getTracks();
          mediaStreamTracks.forEach((track) => {
            track.stop();
          });
          setPCStream(null);
        }
      };

      if (null !== sender.current) {
        setPCStream(stream);
        const videoTrack = stream.getTracks()[0];
        sender.current.replaceTrack(videoTrack);
        return;
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  const handleRemoteControlRequest = () => {
    if (null !== clientStream) {
      const sessionId = localStorage.getItem(
        LOCAL_STORAGE_KEY.REMOTE_CONTROL_SESSION
      );
      const data = {
        pcId: remoteId.current,
        clientId: clientPcId.current,
        sessionId,
      };
      socket.emit(SOCKET_EVENT.REMOTE_CONTROL_REQUEST, data);
    }
  };

  const handleStopRemoteControl = () => {
    setClientPcControl(false);
    localStorage.removeItem(LOCAL_STORAGE_KEY.REMOTE_ACCESS_SESSION);
    const data = {
      pcId: clientPcId.current,
      clientId: clientPcId.current,
    };
    socket.emit(SOCKET_EVENT.STOP_REMOTE_CONTROL, data);
  };

  const handleClientMouseWheel = async (ev) => {
    ev.preventDefault();
    const sessionId = localStorage.getItem(
      LOCAL_STORAGE_KEY.REMOTE_ACCESS_SESSION
    );

    const event = { deltaX: ev.deltaX, deltaY: ev.deltaY };
    const data = {
      type: "mouse_wheel",
      pcId: remoteId.current,
      clientId: clientPcId.current,
      sessionId,
      event: event,
    };
    socket.emit(SOCKET_EVENT.REMOTE_CONTROL_EVENT, data);
  };

  const handleClientKeyDown = async (ev) => {
    ev.preventDefault();

    const sessionId = localStorage.getItem(
      LOCAL_STORAGE_KEY.REMOTE_ACCESS_SESSION
    );
    const eventType = keyboardEventType[ev.type];

    let key;
    key = keyboardKey[ev.key];
    if (undefined === key) {
      key = ev.key.toLowerCase();
    }

    const event = { key, keyboardEventType: eventType };
    const data = {
      type: "key_press",
      pcId: remoteId.current,
      clientId: clientPcId.current,
      sessionId,
      event: event,
    };
    socket.emit(SOCKET_EVENT.REMOTE_CONTROL_EVENT, data);
  };

  const handleClientKeyUp = async (ev) => {
    ev.preventDefault();
    const sessionId = localStorage.getItem(
      LOCAL_STORAGE_KEY.REMOTE_ACCESS_SESSION
    );
    const eventType = keyboardEventType[ev.type];

    let key;
    key = keyboardKey[ev.key];
    if (undefined === key) {
      key = ev.key.toLowerCase();
    }

    const event = { key, keyboardEventType: eventType };
    const data = {
      type: "key_press",
      pcId: remoteId.current,
      clientId: clientPcId.current,
      sessionId,
      event: event,
    };
    socket.emit(SOCKET_EVENT.REMOTE_CONTROL_EVENT, data);
  };

  const handleClientMouseMove = async (ev) => {
    ev.preventDefault();
    const { target, clientX, clientY } = ev;
    const { width, height, x, y } = target.getBoundingClientRect();
    const event = {
      clientWidth: width,
      clientHeight: height,
      clientX: clientX - x,
      clientY: clientY - y,
    };
    const sessionId = localStorage.getItem(
      LOCAL_STORAGE_KEY.REMOTE_ACCESS_SESSION
    );

    socket.emit(SOCKET_EVENT.REMOTE_CONTROL_EVENT, {
      type: "mouse_move",
      pcId: remoteId.current,
      clientId: clientPcId.current,
      sessionId,
      event,
    });
  };

  const handleClientMouseDown = async (event) => {
    event.preventDefault();
    const sessionId = localStorage.getItem(
      LOCAL_STORAGE_KEY.REMOTE_ACCESS_SESSION
    );
    const eventType = mouseEventType[event.type];
    const button = mouseType[event.button];

    if (undefined !== button) {
      const event = { mouseEventType: eventType, button };

      socket.emit(SOCKET_EVENT.REMOTE_CONTROL_EVENT, {
        type: "mouse_toggle",
        pcId: remoteId.current,
        clientId: clientPcId.current,
        sessionId,
        event,
      });
    }
  };

  const handleClientMouseUp = async (event) => {
    event.preventDefault();
    const sessionId = localStorage.getItem(
      LOCAL_STORAGE_KEY.REMOTE_ACCESS_SESSION
    );
    const eventType = mouseEventType[event.type];
    const button = mouseType[event.button];

    if (undefined !== button) {
      const event = { mouseEventType: eventType, button };

      socket.emit(SOCKET_EVENT.REMOTE_CONTROL_EVENT, {
        type: "mouse_toggle",
        pcId: remoteId.current,
        clientId: clientPcId.current,
        sessionId,
        event,
      });
    }
  };

  useEffect(() => {
    socket = io(config.socketURL, {
      auth: { token: localStorage.getItem(LOCAL_STORAGE_KEY.JWT_TOKEN) },
    });
    socket.on(SOCKET_EVENT.CONNECT, () => {
      setServerConnected(true);
    });

    socket.on(SOCKET_EVENT.CONNECT_ERROR, (error) => {
      setServerConnected(false);
      console.log("err", error);
    });

    socket.on(SOCKET_EVENT.DISCONNECT, () => {
      console.log("server disconnected connection");
      setServerConnected(false);
    });

    socket.on(SOCKET_EVENT.JWT_TOKEN, (token) => {
      localStorage.setItem(LOCAL_STORAGE_KEY.JWT_TOKEN, token);
    });

    socket.on(SOCKET_EVENT.CREDENTIAL, (credential) => {
      console.log("credential", credential);
      setCredential(credential);
      remoteId.current = credential.id;
    });

    socket.on(SOCKET_EVENT.REMOTE_ACCESS_REQUEST, async (data) => {
      console.log("remote-acces-request", data);
      if (window.api) {
        window.api.remoteAccessRequest(data);
        return;
      }

      const req = window.confirm(`${data.userName} try to access your pc`);
      if (req) {
        clientPc.current = data;
        getUserMediaWeb();
      } else {
        const rejectionData = {
          clientId: data.userId,
          pcId: remoteId.current,
          message: `PC Remote ID :${remoteId.current} Denied Remote Access Request`,
        };
        socket.emit(SOCKET_EVENT.REMOTE_REQUEST_REJECTION, rejectionData);
      }
    });

    socket.on(SOCKET_EVENT.REMOTE_CONTROL_REQUEST, async (clientId) => {
      console.log("remote-control-request", clientId);
      if (window.api) {
        window.api.remoteControlRequest(clientId);
      } else {
        const data = {
          clientId: clientId,
          pcId: remoteId.current,
          message: `Client Pc Running in Browser `,
        };
        socket.emit(SOCKET_EVENT.REMOTE_CONTROL_REJECTION, data);
      }
    });

    socket.on(SOCKET_EVENT.REMOTE_ACCESS_SESSION, async (sessionId) => {
      console.log("remote-access-session", sessionId);
      localStorage.setItem(LOCAL_STORAGE_KEY.REMOTE_ACCESS_SESSION, sessionId);
      setClientPcControl(true);
    });

    socket.on(SOCKET_EVENT.STOP_REMOTE_CONTROL, async (Id) => {
      setClientPcControl(false);
      localStorage.removeItem(LOCAL_STORAGE_KEY.REMOTE_ACCESS_SESSION);
      setPcControl(false);
    });

    socket.on("error-message", (error) => {
      console.log("err", error);
      setToggleError(true);
      setErrorMessage(error);
      clearTimeout(clearErrorTimeout);
      clearErrorTimeout = setTimeout(() => {
        setErrorMessage(null);
        setToggleError(false);
      }, 5000);
    });

    socket.on(
      SOCKET_EVENT.REMOTE_CONTROL_SESSION,
      ({ remotePcId, session }) => {
        if (remotePcId) clientPcId.current = remotePcId;
        localStorage.setItem(LOCAL_STORAGE_KEY.REMOTE_CONTROL_SESSION, session);
      }
    );

    socket.on(SOCKET_EVENT.RTC_OFFER, (data) => {
      console.log("received offer", data);
      const offerSDP = data.sdp;
      rtcPeerConnection.current
        .setRemoteDescription(new RTCSessionDescription(offerSDP))
        .then(() => {
          rtcPeerConnection.current.createAnswer().then((sdp) => {
            rtcPeerConnection.current.setLocalDescription(sdp);

            const answer = {
              sdp: sdp,
              clientId: data.clientId,
              pcId: remoteId.current,
            };

            console.log("sending answer", answer);
            socket.emit(SOCKET_EVENT.RTC_ANSWER, answer);
          });
        });
    });

    socket.on(SOCKET_EVENT.RTC_ANSWER, (data) => {
      const answerSDP = data.sdp;
      console.log("received answer", data);
      rtcPeerConnection.current.setRemoteDescription(
        new RTCSessionDescription(answerSDP)
      );
    });

    socket.on(SOCKET_EVENT.RTC_ICECANDIDATE, (icecandidate) => {
      rtcPeerConnection.current.addIceCandidate(
        new RTCIceCandidate(icecandidate)
      );
    });
  }, []);

  useEffect(() => {
    if (null !== rtcPeerConnection.current) {
      rtcPeerConnection.current.onicecandidate = (e) => {
        console.log("onicecandidate", clientPcId.current);
        if (e.candidate) {
          const data = {
            clientId: clientPcId.current,
            icecandidate: e.candidate,
          };
          socket.emit(SOCKET_EVENT.RTC_ICECANDIDATE, data);
        }
      };

      rtcPeerConnection.current.oniceconnectionstatechange = (e) => {
        if (
          rtcPeerConnection.current.iceConnectionState ===
            WEBRTC_STATUS.DISCONNECTED ||
          rtcPeerConnection.current.iceConnectionState ===
            WEBRTC_STATUS.CLOSED ||
          rtcPeerConnection.current.iceConnectionState === WEBRTC_STATUS.FAILED
        ) {
          setVideoTab(false);
          const senders = rtcPeerConnection.current.getSenders();
          senders.forEach((sender) =>
            rtcPeerConnection.current.removeTrack(sender)
          );
          if (null !== pcStream.current) {
            const mediaStreamTracks = pcStream.current.getTracks();
            mediaStreamTracks.forEach((track) => {
              track.stop();
            });
            setPCStream(null);
          }
        }

        switch (rtcPeerConnection.current.iceConnectionState) {
          case WEBRTC_STATUS.CONNECTED:
            setWebrtcStatus(WEBRTC_STATUS.CONNECTED);
            break;
          case WEBRTC_STATUS.DISCONNECTED:
            rtcPeerConnection.current.close();
            sender.current = null;
            clientPcId.current = null;
            clientPc.current = null;
            rtcPeerConnection.current = null;
            rtcPeerConnection.current = new RTCPeerConnection(configuration);
            setClientStream(null);
            setClientPcControl(false);
            setWebrtcStatus(WEBRTC_STATUS.DISCONNECTED);
            socket.emit(SOCKET_EVENT.REMOVE_ACCESS_SESSION, remoteId.current);
            localStorage.removeItem(LOCAL_STORAGE_KEY.REMOTE_ACCESS_SESSION);
            localStorage.removeItem(LOCAL_STORAGE_KEY.REMOTE_CONTROL_SESSION);
            break;
          case WEBRTC_STATUS.CLOSED:
            setWebrtcStatus(WEBRTC_STATUS.CLOSED);
            break;
          case WEBRTC_STATUS.FAILED:
            setWebrtcStatus(WEBRTC_STATUS.FAILED);
            break;
          default:
            setWebrtcStatus(WEBRTC_STATUS.UNKNOWN);
            break;
        }
      };

      rtcPeerConnection.current.ontrack = (e) => {
        const stream = e.streams[0];

        e.transceiver.receiver.track.onended = (ev) => {};

        e.transceiver.receiver.track.onmute = (ev) => {
          setClientStream(null);
        };

        e.transceiver.receiver.track.onunmute = (ev) => {
          setClientStream(stream);
          if (!videoTab) setVideoTab(true);
          if (null !== pcStream.current) {
            const mediaStreamTracks = pcStream.current.getTracks();
            mediaStreamTracks.forEach((track) => {
              track.stop();
            });
          }
        };

        if (null !== pcStream.current) {
          const mediaStreamTracks = pcStream.current.getTracks();
          mediaStreamTracks.forEach((track) => {
            track.stop();
          });
        }
        setClientStream(stream);
        setVideoTab(true);
      };

      rtcPeerConnection.current.onnegotiationneeded = (e) => {
        try {
          rtcPeerConnection.current
            .createOffer({
              offerToReceiveVideo: true,
            })
            .then((sdp) => {
              rtcPeerConnection.current.setLocalDescription(sdp);
              console.log("sending offer", sdp);
              const data = {
                sdp: sdp,
                clientId: clientPcId.current,
                pcId: remoteId.current,
              };
              socket.emit(SOCKET_EVENT.RTC_OFFER, data);
            });
        } catch (e) {
          console.log(e);
        }
      };
    }
  }, [rtcPeerConnection.current]);

  useEffect(() => {
    if (window.api) {
      window.api.getAllSystemSources((event, details) => {
        const { pc, sources } = details;
        if (pc) clientPc.current = pc;
        pcSources.current = sources;
        setScreenSelectModal(true);
      });

      window.api.getRemoteRequestRejection((event, { client, response }) => {
        const data = {
          clientId: client.userId,
          pcId: remoteId.current,
          message: `PC Remote ID :${remoteId.current} ` + response,
        };
        socket.emit(SOCKET_EVENT.REMOTE_REQUEST_REJECTION, data);
      });

      window.api.getControlbarInfo((event, info) => {
        switch (info) {
          case ELECTRON_CONTROLBAR_EVENT.LEAVE:
            handleEndRemoteControl();
            break;
          case ELECTRON_CONTROLBAR_EVENT.SHARE:
            if (null !== pcStream.current) {
              const mediaStreamTracks = pcStream.current.getTracks();
              mediaStreamTracks.forEach((track) => {
                track.stop();
              });
              setPCStream(null);
              return;
            }
            window.api.allowSystemSources();

            break;
          case ELECTRON_CONTROLBAR_EVENT.REMOTE_ACCESS:
            setPcControl(false);
            const data = {
              pcId: remoteId.current,
              clientId: clientPcId.current,
            };
            socket.emit(SOCKET_EVENT.STOP_REMOTE_CONTROL, data);
            break;
          default:
            break;
        }
      });

      window.api.getRemoteControlRejection((event, { clientId, response }) => {
        const data = {
          clientId: clientId,
          pcId: remoteId.current,
          message: `PC Remote ID :${remoteId.current} ` + response,
        };
        socket.emit(SOCKET_EVENT.REMOTE_CONTROL_REJECTION, data);
      });

      window.api.AllowRemoteControl((event, clientId) => {
        const data = {
          clientId: clientId,
          pcId: remoteId.current,
        };
        socket.emit(SOCKET_EVENT.ALLOW_REMOTE_CONTROL, data);
        setPcControl(true);
      });

      socket.on(SOCKET_EVENT.REMOTE_CONTROL_EVENT, async (event) => {
        await window.api.sendControlevents(event);
      });
    }
  }, []);

  useEffect(() => {
    const sendControlbarStatus = (share, pcControl) => {
      if (window.api) {
        window.api.sendControlbarStatus({ share, pcControl });
        return;
      }
      setWebControlbar({ window: true, share: share });
    };

    const sendDisconnectInfo = () => {
      if (window.api) {
        window.api.sendDisconnectInfo();
        return;
      }
      setWebControlbar({ window: false, share: false });
    };

    const handleConnectedState = () => {
      if (!videoTab && pcStream.current !== null) {
        sendControlbarStatus(true, pcControl);
      } else if (!videoTab && pcStream.current === null) {
        sendControlbarStatus(false, pcControl);
      } else {
        sendDisconnectInfo();
      }
    };

    const handleDisconnectedState = () => {
      sendDisconnectInfo();
    };

    switch (webrtcStatus) {
      case WEBRTC_STATUS.CONNECTED:
        handleConnectedState();
        break;
      case WEBRTC_STATUS.DISCONNECTED:
        handleDisconnectedState();
        break;
      default:
        sendDisconnectInfo();
        break;
    }

    if (null === stream && pcControl) {
      const data = {
        clientId: clientPcId.current,
        pcId: remoteId.current,
      };
      socket.emit(SOCKET_EVENT.STOP_REMOTE_CONTROL, data);
      setPcControl(false);
    }
  }, [stream, videoTab, webrtcStatus, pcControl]);

  useEffect(() => {
    if (serverConnected) {
      if (window.api) window.api.serverConnected();
    } else {
      if (window.api) window.api.serverError();
      setCredential(null);
    }
  }, [serverConnected]);

  return (
    <>
      <div>
        {toggleError && <ToggleError errorMessage={errorMessage} />}
        {videoTab ? (
          <ClientPCStream
            clientPcId={clientPcId}
            stream={clientStream}
            clientPcControl={clientPcControl}
            handleEndRemoteControl={handleEndRemoteControl}
            handleShareScreen={handleShareScreen}
            handleRemoteControlRequest={handleRemoteControlRequest}
            handleClientMouseMove={handleClientMouseMove}
            handleStopRemoteControl={handleStopRemoteControl}
            handleClientMouseDown={handleClientMouseDown}
            handleClientMouseUp={handleClientMouseUp}
            handleClientMouseWheel={handleClientMouseWheel}
            handleClientKeyUp={handleClientKeyUp}
            handleClientKeyDown={handleClientKeyDown}
          />
        ) : (
          <div className="container">
            <Navbar />

            <div
              className={
                webrtcStatus === WEBRTC_STATUS.CONNECTED
                  ? "disable-main-component"
                  : null
              }
              style={{ minHeight: "500px" }}
            >
              <MainPage
                credential={credential}
                handleRemoteRequest={handleRemoteRequest}
                serverConnected={serverConnected}
              />
            </div>
          </div>
        )}
        {screenSelectModal && (
          <ScreenSelectModal
            handleScreenSelectModal={
              webrtcStatus === WEBRTC_STATUS.CONNECTED
                ? handleScreenSelectModalAfterConnected
                : handleScreenSelectModal
            }
            sources={pcSources.current}
          />
        )}

        {webControlbar.window && (
          <PcControlbar
            webControlbar={webControlbar}
            webControlbarShareScreen={webControlbarShareScreen}
            handleEndRemoteControl={handleEndRemoteControl}
          />
        )}
      </div>
    </>
  );
};

export default RemoteControl;
