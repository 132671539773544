import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';

const NavbarCustom = ({ topics, onSelectTopic, selectedTopic }) => {
  return (
    <Navbar className="navbar-custom-topic" style={{ backgroundColor: '#fff', borderBottom: '1px solid #ccc',overflowY: 'auto', maxHeight: '7vh',justifyContent:"space-between" }}>
      <Nav className="mr-auto" >
        {topics.map((topic, index) => (
          <Nav.Link
            key={index}
            onClick={() => onSelectTopic(topic)}
            className={`text-dark ${selectedTopic === topic ? 'selected' : ''}`}
            style={{ cursor: 'pointer',margin:"0 10px 0 10px"}}
          >
            {topic.name}
          </Nav.Link>
        ))}
      </Nav>
    </Navbar>
  );
};

export default NavbarCustom;
