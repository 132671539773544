export const WEBRTC_STATUS = {
  CONNECTED: "connected",
  DISCONNECTED: "disconnected",
  CLOSED: "closed",
  FAILED: "failed",
  UNKNOWN: "unknown",
  NEW: "new",
  CHECKING: "checking",
  COMPLETED: "completed",
};

export const SOCKET_EVENT = {
  REMOTE_ACCESS_REQUEST: "remote-acces-request",
  REMOTE_REQUEST_REJECTION: "remote-request-rejection",
  ALLOW_REMOTE_CONTROL_ACCESS: "allow-remote-control-access",
  RTC_OFFER: "rtc-offer",
  REMOTE_CONTROL_REQUEST: "remote-control-request",
  STOP_REMOTE_CONTROL: "stop-remote-control",
  REMOTE_CONTROL_EVENT: "remote-control-event",
  CREDENTIAL: "credential",
  JWT_TOKEN: "jwt-token",
  CONNECT_ERROR: "connect_error",
  CONNECT: "connect",
  REMOTE_ACCESS_SESSION: "remote-access-session",
  REMOTE_CONTROL_REJECTION: "remote-control-rejection",
  ERROR_MESSAGE: "error-message",
  REMOTE_CONTROL_SESSION: "remote-control-session",
  RTC_ANSWER: "rtc-answer",
  RTC_ICECANDIDATE: "rtc-icecandidate",
  ALLOW_REMOTE_CONTROL: "allow-remote-control",
  REMOVE_ACCESS_SESSION: "remove-access-session",
  DISCONNECT: "disconnect",
};

export const keyboardEventType = {
  keydown: "down",
  keyup: "up",
};

export const mouseType = {
  0: "left",
  1: "middle",
  2: "right",
};

export const mouseEventType = {
  mousedown: "down",
  mouseup: "up",
};

export const LOCAL_STORAGE_KEY = {
  REMOTE_CONTROL_SESSION: "remoteControlSession",
  REMOTE_ACCESS_SESSION: "remoteAccessSession",
  JWT_TOKEN: "jwtToken",
};

export const ELECTRON_CONTROLBAR_EVENT = {
  LEAVE: "controlbar_leave",
  SHARE: "controlbar_share",
  REMOTE_ACCESS: "controlbar_stop_remote_access",
};

export const keyboardKey = {
  ArrowUp: "up",
  ArrowLeft: "left",
  ArrowDown: "down",
  ArrowRight: "right",
};
