import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

const FileUpload = ({ onFileSelect }) => {
    const [dragOver, setDragOver] = useState(false);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        processFile(file);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        setDragOver(false);
        const file = event.dataTransfer.files[0];
        processFile(file);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        setDragOver(true);
    };

    const handleDragLeave = () => {
        setDragOver(false);
    };

    const processFile = (file) => {
        if (file && file.type === "application/json") {
            const reader = new FileReader();
            reader.onload = (e) => {
                try {
                    const jsonData = JSON.parse(e.target.result);
                    onFileSelect(jsonData);
                } catch (err) {
                    console.log("Error parsing JSON:", err);
                }
            };
            reader.readAsText(file);
        } else {
            console.log("Unsupported file type.");
        }
    };

    return (
        <Container
            className={`file-upload-container d-flex align-items-center justify-content-center ${dragOver ? 'drag-over' : ''}`}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
        >
            <Row className="text-center">
                <Col>
                    <div className="file-upload-box p-4">
                        <h2 className="mb-4">Drop your file here</h2>
                        <p className="mb-3">or</p>
                        <Button variant="dark" className="upload-btn">
                            <label htmlFor="file-input" className="m-0">
                                Upload a file
                            </label>
                        </Button>
                        <input
                            id="file-input"
                            type="file"
                            accept=".json"
                            onChange={handleFileChange}
                            className="file-input"
                        />
                        <p className="mt-3">Use a topic-formatted JSON file. Supported format: JSON</p>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default FileUpload;
