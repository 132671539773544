import { React } from "react";
import "./error.css";

const ToggleError = ({errorMessage}) => {
  return (
    <>
      <p className="custom-error">{errorMessage}</p>
    </>
  );
};

export default ToggleError;
