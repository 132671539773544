import React from 'react';
import { ListGroup } from 'react-bootstrap';

const Sidebar = ({ questions, selectedQuestion, onSelectQuestion }) => {
    return (
        <ListGroup
            className="sidebar-custom"

            style={{ backgroundColor: '#fff', overflowY: 'auto',maxHeight: '93vh', border: '1px solid #ccc',fontSize:"small" }}
        >
            {questions.map((q, index) => (
                <ListGroup.Item
                    key={index}
                    onClick={() => onSelectQuestion(q)}
                    className={q === selectedQuestion ? "selectedQuestion" : ""}
                    style={{ cursor: 'pointer' }}
                >
                    {q.question}
                </ListGroup.Item>
            ))}
        </ListGroup>
    );
};

export default Sidebar;
