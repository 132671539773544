import { React, useRef, useEffect, useState } from "react";
import "./client-video.css";

const ClientVideo = ({
  stream,
  clientPcControl,
  handleClientMouseMove,
  handleClientMouseDown,
  handleClientMouseUp,
  handleClientMouseWheel,
  handleClientKeyUp,
  handleClientKeyDown,
}) => {
  const videoRef = useRef();
  const [sendEvent, setSendEvent] = useState(false);

  const handleStartSendEvent = (ev) => {
    if (!sendEvent) setSendEvent(true);
  };

  const handleStopSendEvent = (ev) => {
    if (sendEvent) setSendEvent(false);
  };

  const handlecontextmenu = (ev) => {
    ev.preventDefault();
  };

  const handleClientClick = (ev) => {
    ev.preventDefault();
  };

  useEffect(() => {
    if (null !== stream) {
      try {
        videoRef.current.srcObject = stream;
        //videoRef.current.onloadedmetadata = (e) => videoRef.current.play();
      } catch (err) {}
    }
  }, [stream]);

  useEffect(() => {
    if (null !== clientPcControl && sendEvent) {
      window.addEventListener("contextmenu", handlecontextmenu);
      window.addEventListener("keydown", handleClientKeyDown);
      window.addEventListener("keyup", handleClientKeyUp);
      window.addEventListener("wheel", handleClientMouseWheel, {
        passive: false,
      });
    } else {
      window.removeEventListener("contextmenu", handlecontextmenu);
      window.removeEventListener("keydown", handleClientKeyDown);
      window.removeEventListener("keyup", handleClientKeyUp);
      window.removeEventListener("wheel", handleClientMouseWheel, {
        passive: false,
      });
    }

    return () => {
      window.removeEventListener("contextmenu", handlecontextmenu);
      window.removeEventListener("keydown", handleClientKeyDown);
      window.removeEventListener("keyup", handleClientKeyUp);
      window.removeEventListener("wheel", handleClientMouseWheel);
    };
  }, [clientPcControl, sendEvent]);

  return (
    <>
      <div className="inner-video-container">
        {stream ? (
          <video
            onMouseMove={clientPcControl ? handleClientMouseMove : null}
            onMouseEnter={clientPcControl ? handleStartSendEvent : null}
            onMouseLeave={clientPcControl ? handleStopSendEvent : null}
            onMouseDown={clientPcControl ? handleClientMouseDown : null}
            onMouseUp={clientPcControl ? handleClientMouseUp : null}
            onClick={clientPcControl ? handleClientClick : null}
            className="video"
            autoPlay={true}
            ref={videoRef}
            
          ></video>
        ) : (
          <div className="stream-svg">
            <div
              style={{
                paddingTop: "30px",
                paddingBottom: "30px",
                paddingLeft: "30px",
                paddingRight: "30px",
                justifyContent: "center",
                textAlign: "center",
                borderRadius: "50%",
                overflow: "hidden",
                position: "relative",
              }}
            >
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#000000"
                  version="1.1"
                  id="Capa_1"
                  width="100%"
                  viewBox="0 0 28.136 28.136"
                >
                  <g>
                    <g>
                      <path d="M14.499,21.246l-1.478-1.109c-0.169-0.127-0.478-0.229-0.689-0.229h-1.987v0.257c0,0.424-0.344,0.768-0.766,0.768    c-0.423,0-0.767-0.344-0.767-0.768v-0.257H6.825c-0.211,0-0.521,0.104-0.689,0.229l-1.478,1.109    c-0.17,0.125-0.135,0.229,0.075,0.229h9.69C14.634,21.475,14.669,21.371,14.499,21.246z" />
                      <path d="M18.389,6.66H0.768C0.347,6.66,0,7.005,0,7.427v9.959c0,0.422,0.347,0.767,0.768,0.767h8.428v2.011    c0,0.213,0.171,0.385,0.383,0.385c0.211,0,0.383-0.172,0.383-0.385v-2.011h8.428c0.421,0,0.769-0.345,0.769-0.767V7.427    C19.156,7.005,18.811,6.66,18.389,6.66z" />
                      <path d="M27.255,6.66H21.8c-0.483,0-0.881,0.396-0.881,0.881v13.052c0,0.484,0.396,0.882,0.881,0.882h5.455    c0.483,0,0.881-0.396,0.881-0.882V7.542C28.135,7.057,27.738,6.66,27.255,6.66z M24.527,20.174c-0.365,0-0.662-0.295-0.662-0.66    c0-0.364,0.297-0.661,0.662-0.661s0.66,0.297,0.66,0.661C25.188,19.879,24.893,20.174,24.527,20.174z M26.289,12.434h-3.523    c-0.484,0-0.881-0.353-0.881-0.782c0-0.431,0.396-0.783,0.881-0.783h3.523c0.484,0,0.882,0.353,0.882,0.783    S26.773,12.434,26.289,12.434z M26.289,9.987h-3.523c-0.484,0-0.881-0.352-0.881-0.783c0-0.43,0.396-0.782,0.881-0.782h3.523    c0.484,0,0.882,0.353,0.882,0.782C27.171,9.635,26.773,9.987,26.289,9.987z" />
                    </g>
                  </g>
                </svg>
              </div>
              <span
                style={{
                  top: "0",
                  color: "white",
                  fontFamily: "monospace",
                  fontWeight: "bold",
                  fontSize: "16px",
                  letterSpacing: "1px",
                  display: "inline-block",
                }}
              >
                Stream Stopped
              </span>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ClientVideo;
