import { React } from "react";
import "./navbar.css";

const Navbar = () => {
  return (
    <>
      <h2 className="navbar-custom text-center">Web Remote Control</h2>
      <hr />
      <div className="navbar-custom-margin"></div>
    </>
  );
};

export default Navbar;
