import { React } from "react";
import "./chat-window.css";

const ChatWindow = ({ clientPcId }) => {
  return (
    <>
      <div className="inner-chat-container">
        chat
        <div className="chat-svg">
          <div
            style={{
              paddingTop: "30px",
              paddingBottom: "30px",
              paddingLeft: "30px",
              paddingRight: "30px",

              borderRadius: "50%",
              overflow: "hidden",
              position: "relative",
            }}
          >
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="#000000"
                width="40%"
                viewBox="0 0 32 32"
                version="1.1"
              >
                <title>chat</title>
                <path d="M25.042 18.672v-12.487c0-1.234-1-2.236-2.236-2.236h-19.571c-1.235-0.001-2.236 1.001-2.236 2.236v12.487c0 1.235 1.001 2.236 2.236 2.236h3.729v5.137l5.704-5.137h10.137c1.237 0 2.237-1.001 2.237-2.236zM28.765 8.858h-2.798c0 0 0 9.972 0 10.909s-1.216 2.142-2.153 2.142-10.945 0.055-10.945 0.055c0 1.235 1.001 1.986 2.236 1.986h4.309l5.622 5.102-0.007-5.102h3.736c1.236 0 2.236-0.751 2.236-1.986v-10.932c0-1.234-1-2.174-2.236-2.174z" />
              </svg>
            </div>
            <span
              style={{
                top: "0",
                color: "white",
                fontFamily: "monospace",
                fontWeight: "bold",
                fontSize: "10px",
                letterSpacing: "1px",
                display: "inline-block",
              }}
            >
              Under Development
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatWindow;
