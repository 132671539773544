import React, { useState, useEffect } from 'react';
import NavbarCustom from './Navbar';
import Sidebar from './Sidebar';
import Content from './Content';
import FileUpload from './FileUpload';
import SavedTopic from '../../data/topics.json';
import { Row, Col } from 'react-bootstrap';
import "./Topic.css"

function Topic() {
  const [topicsData, setTopicsData] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  useEffect(() => {
    if (topicsData && topicsData.topics.length > 0) {
      setSelectedTopic(topicsData.topics[0]);
      setSelectedQuestion(topicsData.topics[0].questions[0]);
    }
  }, [topicsData]);

  useEffect(() => {
    const password = parseInt(localStorage.getItem("admin"))
    if(password === 1234){
      setTopicsData(SavedTopic)
    }
  }, []);

  const handleFileSelect = (data) => {
    if (data && data?.topics?.length > 0) { setTopicsData(data); } else {
      alert("Invalid Topics File")
    }

  };

  const handleSelectTopic = (topic) => {
    setSelectedTopic(topic);
    setSelectedQuestion(topic.questions[0]);
  };

  const handleSelectQuestion = (question) => {
    setSelectedQuestion(question);
  };

  if (!topicsData) {
    return <FileUpload onFileSelect={handleFileSelect} />;
  }

  return (
    <div className="topic-app">
      <NavbarCustom
        topics={topicsData.topics}
        onSelectTopic={handleSelectTopic}
        selectedTopic={selectedTopic}
      />
      <Row className="flex-grow-1">
        <Col md={3} className="d-flex flex-column">
          {selectedTopic && (
            <Sidebar
              questions={selectedTopic.questions}
              selectedQuestion={selectedQuestion}
              onSelectQuestion={handleSelectQuestion}
            />
          )}
        </Col>
        <Col md={9} className="d-flex flex-column" >
          <Content question={selectedQuestion} />
        </Col>
      </Row>
    </div>
  );
}

export default Topic;
