import { React } from "react";
import "./pc-controlbar.css";

const PcControlbar = ({
  webControlbar,
  webControlbarShareScreen,
  handleEndRemoteControl,
}) => {
  return (
    <>
      <div className="pc-controlbar-modal ">
        <div className="pc-controlbar-modal-content">
          <div
            className={
              webControlbar.share
                ? "control-bar-container box"
                : "control-bar-container"
            }
            style={{ borderRadius: "5px" }}
          >
            <div className="inner-container" id="inner-container">
              <div className="inner-control-window">
                <button className="buttton-disabled " disabled>
                  <span className="button__text"> Chat</span>
                  <span className="button__icon">
                    <svg
                      fill="currentColor"
                      aria-hidden="true"
                      width="1em"
                      height="1em"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ paddingBottom: "2px" }}
                    >
                      <path
                        xmlns="http://www.w3.org/2000/svg"
                        d="M20 12C20 16.4183 16.4183 20 12 20C10.5937 20 9.27223 19.6372 8.12398 19C7.53267 18.6719 4.48731 20.4615 3.99998 20C3.44096 19.4706 5.4583 16.6708 5.07024 16C4.38956 14.8233 3.99999 13.4571 3.99999 12C3.99999 7.58172 7.58171 4 12 4C16.4183 4 20 7.58172 20 12Z"
                        stroke="#000000"
                        strokeLinejoin="round"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                </button>

                {!webControlbar.share ? (
                  <button className="control-button" onClick={webControlbarShareScreen}>
                    <span className="button__text" id="share_span">
                      Share
                    </span>
                    <span className="button__icon">
                      <svg
                        fill="currentColor"
                        aria-hidden="true"
                        width="1em"
                        height="1em"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          id="share_path"
                          xmlns="http://www.w3.org/2000/svg"
                          d="M10 14a.5.5 0 0 1-.5-.5V7.7L7.85 9.36a.5.5 0 1 1-.7-.7l2.5-2.5c.2-.2.5-.2.7 0l2.5 2.5a.5.5 0 0 1-.7.7L10.5 7.71v5.79a.5.5 0 0 1-.5.5ZM2 6c0-1.1.9-2 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6Zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1H4Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                  </button>
                ) : (
                  <button className="control-button" onClick={webControlbarShareScreen}>
                    <span className="button__text">Stopp</span>
                    <span className="button__icon">
                      <svg
                        fill="currentColor"
                        aria-hidden="true"
                        width="1em"
                        height="1em"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinejoin="round"
                          strokeLinecap="round"
                          d="M8 17L3 12M3 12L8 7M3 12H21"
                        />
                      </svg>
                    </span>
                  </button>
                )}

                <button className="control-button hang-call" onClick={handleEndRemoteControl}>
                  <span className="button__text-hang-call"> Leave</span>
                  <span className="button__icon-hang-call">
                    <svg
                      fill="currentColor"
                      aria-hidden="true"
                      width="1em"
                      height="1em"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10 6c3.33 0 5.59 1.28 7.15 2.66.66.58.97 1.46.8 2.28l-.15.83c-.15.78-.87 1.3-1.7 1.22l-1.63-.16c-.72-.07-1.25-.59-1.47-1.33-.3-1-.5-1.75-.5-1.75a6.63 6.63 0 0 0-5 0s-.2.75-.5 1.75c-.2.67-.5 1.26-1.2 1.33l-1.63.16c-.81.08-1.6-.43-1.82-1.2l-.25-.84c-.25-.82-.03-1.7.58-2.28C4.1 7.3 6.68 6 10 6Zm3.48 3.53.02.1a46.1 46.1 0 0 0 .46 1.58c.13.45.39.6.6.62l1.64.16c.35.04.57-.17.62-.4l.16-.84c.09-.46-.08-.98-.49-1.34A9.37 9.37 0 0 0 10 7C6.93 7 4.6 8.2 3.37 9.39c-.33.32-.46.8-.31 1.28l.24.83c.1.3.43.53.77.5l1.63-.17.05-.01.05-.05c.07-.09.15-.26.24-.55a115.65 115.65 0 0 0 .46-1.6l.03-.1c.1-.37.45-.62.62-.7a7.62 7.62 0 0 1 5.73 0c.15.07.48.29.59.68v.03Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PcControlbar;
