import { React, useState } from "react";
import "./main-page.css";

const MainPage = ({ credential, handleRemoteRequest, serverConnected }) => {
  const [clientCredential, setClientCredential] = useState({
    userName: "",
    remoteId: "",
    password: "",
  });

  const handleClientCredential = (e) => {
    setClientCredential((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const ReceiveIcon = () => {
    return (
      <>
        <svg
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-box-arrow-left"
          viewBox="0 0 16 16"
          style={{ marginRight: "10px", marginBottom: "3px" }}
        >
          <path
            fillRule="evenodd"
            d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0v2z"
          />
          <path
            fillRule="evenodd"
            d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z"
          />
        </svg>
      </>
    );
  };

  const ConnectIcon = () => {
    return (
      <>
        <svg
          style={{ marginRight: "10px", marginBottom: "3px" }}
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-box-arrow-right"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"
          />
          <path
            fillRule="evenodd"
            d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"
          />
        </svg>
      </>
    );
  };

  return (
    <>
      <div className="container">
        <section id="about" className="about">
          <div className="container">
            <div className="row">
              <div className="col-lg-6" style={{ padding: "10px" }}>
                <div className="row">
                  <button
                    className="nav-link remove-cursor"
                    style={{ color: "blue" }}
                  >
                    <ReceiveIcon />
                    <h3 style={{ fontFamily: "Monospace" }}>
                      Allow Remote Control
                    </h3>
                  </button>
                </div>

                <div
                  className="row tab-pane fade show active"
                  id="pills-login"
                  role="tabpanel"
                  aria-labelledby="tab-login"
                >
                  {/* <form> */}
                  <div className="position-relative mt-4">
                    <input
                      type="text"
                      className="outside"
                      required=""
                      disabled
                    />
                    <span className="floating-label-outside credential-custom-span">
                      Remote ID
                      <span style={{ float: "right" }}>
                        {credential !== null ? credential.id : undefined}
                      </span>
                    </span>
                    <i className="fa fa-user-o input-icon-outside"></i>
                  </div>

                  <div className="position-relative mt-4">
                    <input
                      type="text"
                      className="outside"
                      required=""
                      disabled
                    />
                    <span className="floating-label-outside credential-custom-span">
                      Password
                      <span style={{ float: "right" }}>
                        {credential !== null ? credential.password : undefined}{" "}
                      </span>
                    </span>
                    <i className="fa fa-user-o input-icon-outside"></i>
                  </div>
                  {/* </form> */}
                </div>
              </div>
              <div className="col-lg-6" style={{ padding: "10px" }}>
                <div className="row">
                  <button
                    className="nav-link remove-cursor"
                    style={{ color: "blue" }}
                  >
                    <ConnectIcon />
                    <h3 style={{ fontFamily: "Monospace" }}>
                      Control Remote Computer
                    </h3>
                  </button>
                </div>
                <div
                  className="row tab-pane fade show active"
                  id="pills-register"
                  role="tabpanel"
                  aria-labelledby="tab-register"
                >
                  <form>
                    <div className="position-relative mt-4">
                      <input
                        type="text"
                        className="outside"
                        name="userName"
                        value={clientCredential.userName}
                        onChange={handleClientCredential}
                      />
                      <span className="floating-label-outside custom-input">
                        YOUR NAME
                      </span>
                      <i className="fa fa-user-o input-icon-outside"></i>
                    </div>
                    <div className="position-relative mt-4">
                      <input
                        type="text"
                        className="outside"
                        name="remoteId"
                        value={clientCredential.remoteId}
                        onChange={handleClientCredential}
                      />
                      <span className="floating-label-outside custom-input">
                        CLIENT PC REMOTE ID
                      </span>
                      <i className="fa fa-user-o input-icon-outside"></i>
                    </div>

                    <div className="position-relative mt-4">
                      <input
                        type="password"
                        className="outside"
                        name="password"
                        autoComplete="on"
                        value={clientCredential.password}
                        onChange={handleClientCredential}
                      />

                      <span className="floating-label-outside">PASSWORD</span>
                      <i className="fa fa-user-o input-icon-outside"></i>
                    </div>

                    <button
                      type="button"
                      onClick={() => handleRemoteRequest(clientCredential)}
                      style={{ width: "100%", marginTop: "30px" }}
                      className={
                        serverConnected
                          ? "btn btn-outline-success"
                          : "btn btn-outline-danger custom-network-error"
                      }
                      disabled={!serverConnected}
                    >
                      {serverConnected ? "connect" : "Network Error"}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default MainPage;
