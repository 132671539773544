import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./screenSelectModal.css";
import { React } from "react";

export default function ScreenSelectModal({
  handleScreenSelectModal,
  sources,
}) {
  return (
    <div>
      <Modal
        contentClassName="transparentBgClass"
        backdrop="static"
        show={true}
        onHide={() => handleScreenSelectModal(null)}
        animation={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="lead ">Share your screen</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            maxHeight: "calc(100vh - 210px)",
            overflowY: "auto",
          }}
        >
          {sources.map((source, index) =>
            index % 2 === 1 ? null : (
              <>
                <Row
                  style={{ marginTop: 20, marginLeft: 20, marginRight: 20 }}
                  key={index}
                >
                  <Col xs={6} key={index}>
                    <div
                      className="custom-div"
                      onClick={() => {
                        handleScreenSelectModal(source);
                      }}
                    >
                      <div className="custom-inner-div">
                        <img
                          src={source.thumbnailURL}
                          alt="Instagram"
                          style={{ width: "100%" }}
                        />
                        <div className="window-name text-center">
                          {source.name}
                        </div>
                      </div>
                    </div>
                  </Col>

                  {sources[index + 1] ? (
                    <>
                      <Col xs={6} key={index + 1}>
                        <div
                          className="custom-div"
                          onClick={() => {
                            handleScreenSelectModal(sources[index + 1]);
                          }}
                        >
                          <div className="custom-inner-div">
                            <img
                              src={sources[index + 1].thumbnailURL}
                              alt="Instagram"
                              style={{ width: "100%" }}
                            />
                            <div className="window-name text-center">
                              {sources[index + 1].name}
                            </div>
                          </div>
                        </div>
                      </Col>
                    </>
                  ) : null}
                </Row>
              </>
            )
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}
