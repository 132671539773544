import { React, useEffect, useState, useRef } from "react";
import ClientVideo from "./client-video/client-video";
import ChatWindow from "./chat-window/chat-window";
import ControlWindow from "./control-window/control-window";
import "./client-pc-stream.css";

const ClientPCStream = ({
  clientPcId,
  stream,
  handleEndRemoteControl,
  handleShareScreen,
  handleRemoteControlRequest,
  clientPcControl,
  handleClientMouseMove,
  handleStopRemoteControl,
  handleClientMouseDown,
  handleClientMouseUp,
  handleClientMouseWheel,
  handleClientKeyUp,
  handleClientKeyDown,
}) => {
  const [isElectron, setIsElectron] = useState(false);
  const controlbarRef = useRef(null);
  const videochatRef = useRef(null);

  const handleVideoContainerSize = () => {
    videochatRef.current.style.maxHeight =
      window.innerHeight - controlbarRef.current.offsetHeight - 2 + "px";
  };

  useEffect(() => {
    if (window.api) {
      setIsElectron(true);
    }

    handleVideoContainerSize();

    window.addEventListener("resize", handleVideoContainerSize);

    return () => {
      window.removeEventListener("resize", handleVideoContainerSize);
    };
  }, []);

  return (
    <div className="client-pc-stream-container" style={{ height: "100vh" }}>
      {/* <div className="top-bar-container" style={{ height: window.innerHeight * 0.05 }}>
        Contolling PC ID : {clientPcId.current}
      </div> */}
      <div className="video-chat-container" ref={videochatRef}>
        <div
          className={
            isElectron ? "electron-video-container" : "video-container"
          }
        >
          <ClientVideo
            stream={stream}
            clientPcControl={clientPcControl}
            handleClientMouseMove={handleClientMouseMove}
            handleClientMouseDown={handleClientMouseDown}
            handleClientMouseUp={handleClientMouseUp}
            handleClientMouseWheel={handleClientMouseWheel}
            handleClientKeyUp={handleClientKeyUp}
            handleClientKeyDown={handleClientKeyDown}
          />
        </div>
        <div
          className={isElectron ? "electron-chat-container" : "chat-container"}
        >
          <ChatWindow clientPcId={clientPcId.current} />
        </div>
      </div>
      <div className="control-bar-container" ref={controlbarRef}>
        <ControlWindow
          stream={stream}
          clientPcControl={clientPcControl}
          handleEndRemoteControl={handleEndRemoteControl}
          handleShareScreen={handleShareScreen}
          handleRemoteControlRequest={handleRemoteControlRequest}
          handleStopRemoteControl={handleStopRemoteControl}
        />
      </div>
    </div>
  );
};

export default ClientPCStream;
