import { React, useState } from "react";
import { useNavigate } from "react-router-dom";
import AnimationSection from "../profile/animation-sestion/animation-section";

function Navbar({ currentPage }) {
  const navigate = useNavigate();
  const [mobileView, setMobileView] = useState(false);

  const handleHomeRoute = () => {
    navigate("/");
  };

  const handleDeveloperRoute = () => {
    navigate("/developer");
  };

  const handleWebRemoteControlRoute = () => {

    window.open("/web-remote-control","_blank")
    // const platform = () => {
    //   const details = navigator.userAgent;

    //   const regexp = /android|iphone|kindle|ipad/i;
    //   return regexp.test(details);
    // };

    // const newTab = window.open("/", "_blank");
    // newTab.onload = (e) => {
    //   if (platform()) {
    //     console.log("userAgent");
    //     setTimeout(() => {
    //       newTab.postMessage({ navigate: "/web-remote-control" }, "*");
    //     }, 1000);
    //     return;
    //   }

    //   newTab.postMessage({ navigate: "/web-remote-control" }, "*");
    // };
  };

  return (
    <>
      <div className=" fixed-top">
        <section id="topbar" className="d-flex align-items-center">
          <div className="container d-flex justify-content-center justify-content-md-between">
            <div className="contact-info d-flex align-items-center">
              <i className="bi bi-envelope d-flex align-items-center">
                <a href="mailto:shabinsv7@gmail.com">shabinsv7@gmail.com</a>
              </i>
              <i className="bi bi-phone d-flex align-items-center ms-4">
                <a href="callto:+9177736107770">+91 77736107770</a>
              </i>
            </div>
            <div className="social-links d-none d-md-flex align-items-center">
              <a
                href="https://in.linkedin.com/in/shabin-sv-831872228"
                rel="noreferrer"
                target="_blank"
                className="linkedin"
              >
                <i className="bi bi-linkedin"></i>
              </a>

              <a
                href="https://github.com/shabinsv"
                target="_blank"
                rel="noreferrer"
                className="linkedin"
              >
                <i className="bi bi-github"></i>
              </a>
            </div>
          </div>
        </section>

        <header id="header" className="d-flex align-items-center">
          <div className="container d-flex justify-content-between align-items-center">
            <div className="logo">
              <h1>
                {currentPage === "home" ? (
                  <div style={{ fontSize: "18px" }}>Remote Desktop</div>
                ) : (
                  <div style={{ fontSize: "18px" }} className="animation-mobile-view">
                    <AnimationSection content={"shabin Sv"} removeTag={false} />
                  </div>
                )}
              </h1>
            </div>

            <nav
              id="navbar"
              className={mobileView ? "navbar navbar-mobile" : "navbar"}
            >
              <ul>
                <li>
                  <div
                    className={currentPage === "home" ? "active" : null}
                    onClick={handleHomeRoute}
                    style={{ cursor: "pointer" }}
                  >
                    Home
                  </div>
                </li>
                <li>
                  <div
                    className={currentPage === "developer" ? "active" : null}
                    onClick={handleDeveloperRoute}
                    style={{ cursor: "pointer" }}
                  >
                    Developer Info
                  </div>
                </li>
                <li>
                  <div
                    className="btn-getstarted"
                    onClick={handleWebRemoteControlRoute}
                    style={{ cursor: "pointer",maxWidth:"194px" }}
                  >
                    Web Remote Control <i className="bi bi-arrow-right"></i>
                  </div>
                </li>
              </ul>
              <i
                className={
                  mobileView
                    ? "bi bi-x mobile-nav-toggle"
                    : "bi bi-list mobile-nav-toggle"
                }
                onClick={() => {
                  setMobileView(!mobileView);
                }}
              ></i>
            </nav>
          </div>
        </header>
      </div>
    </>
  );
}

export default Navbar;
