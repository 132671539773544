import { React } from "react";

function Footer() {
  return (
    <>
    <footer id="footer">
        <div className="container">
          <div className="copyright">
            &copy; 2023 {" "}
            <strong>
              <span>shabinsv.com</span>
            </strong>
           
          </div>
          
        </div>
      </footer>
    </>
  );
}

export default Footer;
